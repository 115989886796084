<template>
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <div class="m-icone direita">
                            <div class="pesquisa">
                                <input type="text" placeholder="Pesquise aqui" v-model="filtroNome" @input="pesquisaComFiltro" />
                                <a class="icone-pesquisa" title="Pesquise"></a>
                            </div>
                        </div>
                        <h2>Colaborador</h2>
                    </div>
                </div>
                <div class="margem container">
                    <div class="submit m-b">
                        <button @click="adicionarColaborador">Cadastrar Colaborador</button>
                    </div>
                    <div class="bloco margem">
                        <table class="tabela alinha-centro">
                            <thead class="alinha-centro">
                                <tr>
                                    <th>Nome</th>
                                    <th>CPF</th>
                                    <th>Celular</th>
                                    <th>E-mail</th>
                                    <th>Data de Nascimento</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody class=" alinha-centro">
                                <tr v-for="item in colaboradoresFiltrados" :key="item.id">
                                    <td>{{ item.nomeCompleto }}</td>
                                    <td>{{ item.CPF }}</td>
                                    <td>{{ item.celular }}</td>
                                    <td>{{ item.email }}</td>
                                    <td>{{ formatarData(item.dtNasc) }}</td>
                                    <td>
                                        <div style="display: flex;">
                                            <div>
                                                <a @click="abrirEnvelope(item)" title="Clique para abrir o envelope" class="icone-email"></a>
                                            </div>
                                            <div>
                                                <a style="transform: scale(0.8)" @click="editarColaborador(item)" title="Clique para editar colaborador" class="icone-editar"></a>
                                            </div>
                                            <div>
                                                <a @click="abrirModalExcluir(item)" title="Clique para excluir colaborador" class="icone-lixeira"></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- <div class="paginacao">
                            <a href="#" class="ativo">1</a>
                            <a href="#">2</a>
                            <a href="#">3</a>
                            <span>...</span>
                            <a href="#">16</a>
                        </div> -->
                    </div>
                </div>

                <!-- MODAL QRCODE -->
                <div class="modal-mask" v-if="showQRCodeModal" @click="fecharModalFora">
                    <div class="modal-container" style="height: min-content; width: 50rem;">
                        <div style="display: flex; justify-content: center"></div>
                        <br />
                        <div class="modal-body">
                            <span>Deseja enviar o QR Code para o colaborador <b>{{ selectedCollaboratorNome }} </b> para o e-mail <b>{{ selectedCollaboratorEmail }}</b>?</span>
                            <br /><br />
                            <div class="modal-footer">
                                <button type="button" @click="confirmarEnvioQRCode">Confirmar</button>&nbsp;&nbsp;
                                <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL QR CODE -->

                <!-- MODAL EXCLUIR  -->
                <div class="modal-mask" v-if="showDeleteModal" @click="fecharModalFora">
                    <div class="modal-container" style="height: min-content; width: 50rem;">
                        <div style="display: flex; justify-content: center"></div>
                        <br>
                        <div class="modal-body">
                            <span>Confirma a exclusão do colaborador: <strong>{{ nomeToDelete }}</strong>?</span>
                        
                            <div class="modal-footer">
                                <br><br>
                                <button type="button" @click="confirmarExclusao">Confirmar</button>&nbsp;&nbsp;
                                <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL EXCLUIR -->

            </section>
       
</template>

<script>
import Setores from '../../models/Setor'
import setorService from '../../services/setor-service';
import { createToaster } from "@meforma/vue-toaster";
import { api } from "roboflex-thalamus-request-handler";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: 'ControleDeColaboradorRH',
    data() {
        return {
            filtroSetor: '',
            setores: [],
            setoresVisitante: [],
            pessoaIDModal: null,
            pessoaNomeModal: null,
            pessoaCPFModal: null,
            dadosVisitaModal: {},
            localId: '',
            filtroNome: '',
            result: '',
            error: '',
            colaborador: [],
            localData: [],
            page: 1,
            filtro: '',
            nome: null,
            totalPages: null,
            idToDelete: null,
            nomeToDelete: null,
            idColaborador: null,
            selectedCollaboratorEmail: null,
            selectedCollaboratorNome: null,
            showQRCodeModal: false,
            showDeleteModal: false,
        }
    },
    mounted() {
        this.buscaColaborador(this.page);
        this.obterTodosSetores();
    },
    computed: {
        colaboradoresFiltrados() {
            const buscaNome = this.filtroNome.toLowerCase();
            return this.colaborador.filter(item =>
                item.nomeCompleto.toLowerCase().includes(buscaNome)
            );
        },
        paginatedData() {
            const startIndex = this.currentPage * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.colaborador.slice(startIndex, endIndex);
        },
    },
    methods: {
        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.fecharModal();
            }
        },
        formatarData(data) {
            if (!data) return '';
            const [year, month, day] = data.split('-');
            return `${day}/${month}/${year}`;
        },
        pesquisar() {
            this.$router.push({ name: "PesquisaView" });
        },
        mostraGenero(generoAbreviado) {
            if (generoAbreviado === 'f') {
                return 'Feminino';
            } else if (generoAbreviado === 'm') {
                return 'Masculino';
            } else {
                return generoAbreviado;
            }
        },
        obterTodosSetores() {
            setorService.obterTodos()
                .then((response) => {
                    this.setores = response.data.map((p) => new Setores(p));
                })
                .catch(error => {
                    console.log(error);
                    toaster.error("Erro ao carregar lista de setores", { timeout: 2000 });
                });
        },
        ordenarPessoas(a, b) {
            return (a.id < b.id) ? -1 : (a.id > b.id) ? 1 : 0;
        },
        async buscaColaborador() {
            try {
                const response = await api.get(`pessoa`);
                this.colaborador = response.data;
                this.totalPages = response.data.last_page;
            } catch (error) {
                console.error('Error:', error);
                toaster.error("Erro ao buscar colaboradores");
            }
        },
        adicionarColaborador() {
            this.$router.push({ name: "AdicionarColaborador" });
        },
        editarColaborador(colaborador) {
            this.$router.push({ name: "ColaboradorEditar", params: { id: colaborador.id } });
        },
        abrirModalExcluir(colaborador) {
            this.idToDelete = colaborador.id;
            this.nomeToDelete = colaborador.nomeCompleto;
            this.showDeleteModal = true;
        },
        confirmarExclusao() {
            const id = this.idToDelete;
            const index = this.colaborador.findIndex(item => item.id === id);

            if (index !== -1) {
                api.delete(`pessoa/${id}`)
                    .then(response => {
                        if (response.status === 200 || response.status === 204) {
                            this.buscaColaborador(this.page);
                            toaster.show(`Colaborador excluido com sucesso`, { type: "success" });
                        } else {
                            toaster.show(`Falha ao deletar`, { type: "error" });
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        toaster.error("Erro ao excluir colaborador");
                    });
            }
            this.fecharModal();
        },
        async pesquisaColaborador(searchTerm = '') {
            try {
                const response = await api.get(`pessoa`);
                this.colaborador = response.data || [];
                this.colaborador = this.colaborador.filter(item => item.nomeCompleto.toLowerCase().includes(searchTerm.toLowerCase()));
            } catch (error) {
                console.error('Error:', error);
                toaster.error("Erro ao pesquisar colaboradores");
            }
        },
        pesquisaComFiltro() {
            this.pesquisaColaborador(this.filtroNome);
        },
        async abrirEnvelope(item) {
            this.selectedCollaboratorEmail = item.email;
            this.selectedCollaboratorNome = item.nomeCompleto;
            this.showQRCodeModal = true;
        },
        async enviarQRCode(id) {
            this.idColaborador = id;
            const selectedCollaborator = this.colaborador.find(item => item.id === id);
            if (selectedCollaborator) {
                this.selectedCollaboratorEmail = selectedCollaborator.email;
                this.selectedCollaboratorNome = selectedCollaborator.nomeCompleto;
            }
        },
        async confirmarEnvioQRCode() {
            const id = this.idColaborador;
            try {
                const response = await api.post(`pessoa/enviar-qrcode/${id}`);
                if (response.cod === 1) {
                    toaster.show(`Falha ao enviar QR Code`, { type: 'error' });
                } else {
                    toaster.show(`QR Code enviado`, { type: 'success' });
                }
            } catch (error) {
                console.error('Error:', error);
                toaster.show(`Falha ao enviar QR Code`, { type: 'error' });
            }
        },
        fecharModal() {
            this.showQRCodeModal = false;
            this.showDeleteModal = false;
        }
    },
}
</script>
