<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Vincular usuário a grupos</h2>
            </div>
        </div>
        <div class="grid-2 margem container">
            <div class="bloco margem">
                <label>Selecione um grupo: </label>
                <select style="width: 500px;" v-model="grupoSelecionado" @change="handleGrupoSelecionadoChange">
                                    <option value="" disabled>Selecione</option>
                                    <option v-for="item in gruposDisponiveis" :key="item.id" :value="item.id">{{ item.nome }}</option>
                                </select>
    
                <div v-if="grupoSelecionado">
                    <br><br>
                    <label>Usuários para adicionar:</label>
                    <input style="width: 500px;" v-model="searchQuery" @focus="abrirLista" @input="filtrarUsers" @blur="fecharLista" placeholder="Pesquisar Usuário" type="text" />
                    <div v-if="listaAberta && filteredUsers.length" style="background-color: white; z-index: 99999; max-height: 20rem; overflow: auto; position: absolute; width: 30.5rem; border: 1px solid var(--cor-separador);">
                        <ul style="list-style: none;">
                            <li v-for="user in filteredUsers" :key="user.id">
                                <div @click="toggleUser(user)" style="display: flex; align-items: center; padding: 5px;" :style="{'color': isUserInGroup(user) ? 'gray' : 'black', 'cursor': isUserInGroup(user) ? 'not-allowed' : 'pointer'}">
                                    {{ user.name }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
    
                <div class="responsaveis-list" style="max-height: 500px; overflow-y: auto; overflow-x: hidden;">
                    <ul style="list-style: none;">
                        <div class="row row-cols-auto">
                            <div style="width: fit-content; border: 1px solid black; border-radius: 10px; padding: 5px; margin: 5px;" class="col" v-for="(responsavel, index) in grupoUsuario" :key="index">
                                <li>{{ responsavel.name }}
                                    <i class="fa-solid fa-circle-xmark" style="color: var(--cor-erro);" @click="desassociarUsuario(responsavel)"></i>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
           
        </div>
    </section>
</template>

<script>
import Grupo from '@/models/Grupo'
import grupoService from '@/services/grupo-service'
import Usuario from '@/models/Usuario'
import usuarioService from '@/services/usuario-service'
import grupoUsuarioService from '@/services/grupo_usuario-service'

export default {
    name: "VinculoDeGrupo",
    data() {
        return {
            gruposDisponiveis: [],
            grupoSelecionado: null,
            usuarios: [],
            grupoUsuario: [],
            searchQuery: "",
            filteredUsers: [],
            listaAberta: false
        }
    },
    methods: {
        abrirLista() {
            this.listaAberta = true;
        },
        filtrarUsers() {
            const query = this.searchQuery.toLowerCase();
            this.filteredUsers = this.usuarios.filter(user => user.name.toLowerCase().includes(query));
        },
        fecharLista() {
            setTimeout(() => {
                this.listaAberta = false;
                this.filteredUsers = [];
            }, 200);
        },
        toggleUser(user) {
            if (this.isUserInGroup(user)) {
                this.desassociarUsuario(user);
            } else {
                this.associarUsuario(user);
            }
        },
        associarUsuario(user) {
            grupoUsuarioService.cadastrar(user.id, this.grupoSelecionado)
                .then(() => {
                    this.grupoUsuario.push(user);
                    this.filtrarUsers(); // Atualiza a lista filtrada
                })
                .catch(error => {
                    console.error('Error associating user:', error);
                });
        },
        desassociarUsuario(user) {
            grupoUsuarioService.deletarTeste(user.id, this.grupoSelecionado)
                .then(() => {
                    this.grupoUsuario = this.grupoUsuario.filter(u => u.id !== user.id);
                    this.filtrarUsers(); // Atualiza a lista filtrada
                })
                .catch(error => {
                    console.error('Error disassociating user:', error);
                });
        },
        isUserInGroup(user) {
            return this.grupoUsuario.some(u => u.id === user.id);
        },
        handleGrupoSelecionadoChange() {
            if (this.grupoSelecionado) {
                this.obterUsuarioPorGrupo(this.grupoSelecionado);
            }
        },
        obterUsuarioPorGrupo(id) {
            grupoUsuarioService.obterPorId(id)
                .then(response => {
                    this.grupoUsuario = response.data;
                    this.filtrarUsers();
                })
                .catch(error => {
                    console.log(error)
                });
        },
        getAllUsuarios() {
            usuarioService.obterTodos()
                .then(response => {
                    this.usuarios = response.data.map(p => new Usuario(p));
                    this.filtrarUsers();
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getAllGrupos() {
            grupoService.obterTodos()
                .then(response => {
                    this.gruposDisponiveis = response.data.data.map(p => new Grupo(p));
                })
                .catch(error => {
                    console.log(error)
                })
        },
    },
    mounted() {
        this.getAllGrupos();
        this.getAllUsuarios();
    }
}
</script>
