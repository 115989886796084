<template>
   
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <h2>{{ modoCadastro ? "Cadastrar" : "Editar" }} Setor</h2>
                    </div>
                </div>
                <div class="grid-2 margem container">
                    <fieldset class="grid-2 bloco margem">
                        <div>
                            <label for="nomeCompleto">Nome</label>
                            <input type="text" id="nomeCompleto" v-model="setor.nome">
                        </div>
                    </fieldset>
                </div>
                <div class="grid-2 margem container">
                    <div class="submit m-b">
                        <button @click="salvarSetor">Salvar</button>
                        <button @click="cancelar" class="acao-secundaria">Cancelar</button>
                    </div>
                </div>
            </section>
    
</template>

<script>
import Setor from '@/models/Setor'
import setorService from '@/services/setor-service';
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: "SetorComponent",
    data() {
        return {
            setor: new Setor(),
            modoCadastro: true,
            loading: false
        }
    },
    mounted() {
        let id = this.$route.params.id;
        if (!id) return;

        this.modoCadastro = false;
        this.obterSetorporId(id);
    },
    methods: {
        async obterSetorporId(id) {
            try {
                const response = await setorService.obterPorId(id);
                this.setor = new Setor(response.data);
            } catch (error) {
                console.error('Error fetching setor:', error);
                if (error.response && error.response.data) {
                    console.error('Response data:', error.response.data);
                }
                toaster.show(`Erro ao obter setor`, { type: "error" });
            }
        },
        async cadastrarSetor() {
            this.loading = true;

            if (!this.setor.nome) {
                this.loading = false;
                toaster.show(`Por favor preencha o nome do setor`, { type: "error" });
            } else {
                try {
                    await setorService.cadastrar(this.setor);
                    this.loading = false;
                    this.setor = new Setor();
                    toaster.show(`Setor cadastrado com sucesso!`, { type: "success" });
                    this.$router.push({ name: "ControleDeSetores" });
                } catch (error) {
                    this.loading = false;
                    toaster.show(`Erro no cadastrado do setor!`, { type: "error" });
                    console.error('Error registering setor:', error);
                }
            }
        },
        salvarSetor() {
            (this.modoCadastro) ? this.cadastrarSetor() : this.atualizarSetor();
        },
        async atualizarSetor() {
            this.loading = true;

            if (!this.setor.nome) {
                toaster.show(`Por favor preencha o nome do setor`, { type: "error" });
                this.loading = false;
            } else {
                try {
                    await setorService.atualizar(this.setor);
                    this.loading = false;
                    toaster.show(`Setor atualizado com sucesso!`, { type: "success" });
                    this.$router.push({ name: "ControleDeSetores" });
                } catch (error) {
                    this.loading = false;
                    toaster.show(`Erro ao atualizar setor`, { type: "error" });
                    console.error('Error updating setor:', error);
                }
            }
        },
        cancelar() {
            this.setor = new Setor();
            this.$router.push({ name: "ControleDeSetores" });
        }
    }
}
</script>

<style scoped>
</style>
