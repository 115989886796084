<template>
    
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <div class="m-icone direita">
                            <div class="pesquisa">
                                <input type="text" placeholder="Pesquise aqui" v-model="filtroNome" @input="pesquisaComFiltro" />
                                <a class="icone-pesquisa" title="Pesquise"></a>
                            </div>
                        </div>
                        <h2>Funcionalidades</h2>
                    </div>
                </div>
                <div class="margem container">
                    <div class="submit m-b">
                        <button @click="adicionarFuncionalidade">Cadastrar Funcionalidade</button>
                    </div>
                    <div class="bloco margem">
                        <table class="tabela alinha-centro">
                            <thead class="alinha-centro">
                                <tr>
                                    <th>Nome</th>
                                    <th>URL</th>
                                    <!-- <th>Menu Principal</th>
                                    <th>ID Sistema</th> -->
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody class="alinha-centro">
                                <tr v-for="item in funcionalidadesFiltradas" :key="item.id">
                                    <td>{{ item.nome }}</td>
                                    <td>{{ item.URL }}</td>
                                    <!-- <td>{{ item.menu }}</td>
                                    <td>{{ item.sistema_id }}</td> -->
                                    <td>
                                        <div class="alinha-centro" style="display: flex; width: 100%; justify-content: center;">
                                            <div>
                                                <a style="transform: scale(0.8)" @click="editarFuncionalidade(item)" title="Clique para editar a funcionalidade" class="icone-editar"></a>
                                            </div>
                                            <div>
                                                <a @click="abrirModalExcluir(item)" title="Clique para excluir funcionalidade" class="icone-lixeira"></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- MODAL EXCLUIR -->
                <div class="modal-mask" v-if="showDeleteModal" @click="fecharModalFora">
                    <div class="modal-container" style="height: min-content; width: 50rem;">
                        <div style="display: flex; justify-content: center"></div>
                        <br>
                        <div class="modal-body">
                            <span>Confirma a exclusão da funcionalidade: <strong>{{ nomeToDelete }}</strong>?</span>
                       
                            <div class="modal-footer">
                                <br><br>
                                <button type="button" @click="confirmarExclusao">Confirmar</button>&nbsp;&nbsp;
                                <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL EXCLUIR -->
            </section>
      
</template>

<script>
import funcionalidadeService from '@/services/funcionalidade-service'
import Funcionalidade from '@/models/Funcionalidade'
import { api } from "roboflex-thalamus-request-handler";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: "ControleDeFuncionalidades",
    components: {},
    data() {
        return {
            funcionalidades: [],
            info: null,
            currentPage: 0,
            itemsPerPage: 10,
            filtroNome: '',
            nome: null,
            page: 1,
            lastPage: null,
            idToDelete: null,
            nomeToDelete: null,
            showDeleteModal: false,
        };
    },
    computed: {
       funcionalidadesFiltradas(){
        return this.funcionalidades.filter(item => {
            return item.nome.toLowerCase().includes(this.filtroNome.toLowerCase());
        });


    }
    },
    methods: {
        getAllFuncionalidades() {
            funcionalidadeService.obterTodos()
                .then((response) => {
                    this.funcionalidades = response.data.data.map((p) => new Funcionalidade(p));
                })
                .catch(error => {
                    console.log(error)
                })
        },
        adicionarFuncionalidade() {
            this.$router.push({ name: "AdicionarFuncionalidade" })
        },
        editarFuncionalidade(funcionalidade) {
            this.$router.push({ name: "EditarFuncionalidade", params: { id: funcionalidade.id } })
        },
        abrirModalExcluir(funcionalidade) {
            this.idToDelete = funcionalidade.id;
            this.nomeToDelete = funcionalidade.nome;
            this.showDeleteModal = true;
        },
        confirmarExclusao() {
            const id = this.idToDelete;
            const index = this.funcionalidades.findIndex(item => item.id === id);
            if (index !== -1) {
                api.delete(`funcionalidade/${id}`)
                    .then(response => {
                        if (response.status === 200 || response.status === 204) {
                            this.buscaFuncionalidade(this.page);
                            toaster.show(`Funcionalidade excluída com sucesso`, { type: "success" });
                        } else {
                            toaster.show(`Falha ao deletar`, { type: "error" });
                            console.error('Falha ao deletar', response.status);
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            this.fecharModal();
        },
        fecharModal() {
            this.showDeleteModal = false;
        },
        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.fecharModal();
            }
        },
       
        async buscaFuncionalidade(page) {
            try {
                const response = await fetch(`funcionalidade?page=${page}`);
                const responseData = await response.json();
                this.funcionalidades = responseData.data || [];
                this.lastPage = responseData.last_page || 1;
                this.page = page;
            } catch (error) {
                console.error('Error:', error);
            }
        },
        pesquisaComFiltro() {
            this.currentPage = 0;
        }
    },
    mounted() {
        this.getAllFuncionalidades();
        this.buscaFuncionalidade(this.page);
    }
};
</script>

<style scoped>
</style>
