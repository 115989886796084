<template>
  
            <section>
                <div class="titulo">
                    <div class="margem container">
    
                        <h2>{{ modoCadastro ? "Cadastrar" : "Editar" }} Usuário</h2>
                    </div>
                </div>
                <div class="grid-2 margem container">
                    <fieldset class="grid-2 bloco margem">
                        <div>
                            <label for="nomeCompleto">Nome</label>
                            <input type="text" id="nomeCompleto" v-model="usuario.name">
                        </div>
                        <div>
                            <label for="email">Email</label>
                            <input type="text" id="email" v-model="usuario.email">
                        </div>
                       
                    </fieldset>
    
                </div>
                <div class="grid-2 margem container">
                    <div class="submit m-b"><button @click="salvarUsuario">Salvar</button>
                        <button @click="cancelar" class="acao-secundaria">Cancelar</button>
                    </div>
                </div>
    
            </section>
   
</template>


<script>
import Usuario from '@/models/Usuario'
import usuarioService from '@/services/usuario-service';
import grupoService from '@/services/grupo-service';
import Grupo from '@/models/Grupo'
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});


export default {
    name: "UsuarioComponent",
    data() {
        return {
            usuario: new Usuario(),
            modoCadastro: true,
            grupos: [],
            grupoSelecionado: null,
            loading: false
        }
    },


    mounted() {

        this.getAllGrupos()

        let id = this.$route.params.id;
        if (!id)

            return;

        this.modoCadastro = false;
        this.obterUsuarioPorId(id);

    },
    methods: {

        getAllGrupos() {
            grupoService.obterTodos()
                .then(response => {
                    this.grupos = response.data.data.map((p) => new Grupo(p));
                })
                .catch(error => {
                    console.log(error)
                })

        },

        obterUsuarioPorId(id) {
            usuarioService.obterPorId(id)
                .then(response => {
                    this.usuario = new Usuario(response.data);
                })
                .catch(error => {
                    console.log(error)
                })
        },
        cadastrarUsuario() {
            this.loading = true;

            if (!this.usuario.name || !this.usuario.email) {
                toaster.show(`Por favor preencha o nome e email`, { type: "error" });
                this.loading = false

            } else {
                usuarioService.cadastrar(this.usuario)
                    .then(() => {
                        this.loading = false
                        this.usuario = new Usuario();
                        toaster.show(`Usuário cadastrado com sucesso!`, { type: "success" });
                        this.$router.push({ name: "ControleDeUsuarios" })

                    })
                    .catch(error => {
                        this.loading = false
                        toaster.show(`Erro no cadastro de usuário`, { type: "error" });
                        console.log(error);
                    });
            }

        },

        salvarUsuario() {

            (this.modoCadastro) ? this.cadastrarUsuario(): this.atualizarUsuario();
        },
        atualizarUsuario() {
            this.loading = true

            if (!this.usuario.name || !this.usuario.email) {
                toaster.show(`Por favor preencha nome e email`, { type: "error" });
                this.loading = false


            } else {
                usuarioService.atualizar(this.usuario)
                    .then(() => {
                        this.loading = false
                        toaster.show(`Usuário atualizado com sucesso!`, { type: "success" });
                        this.$router.push({ name: "ControleDeUsuarios" });

                    })
                    .catch(error => {
                        this.loading = false
                        toaster.show(`Erro ao atualizar usuário`, { type: "error" });
                        console.log(error);
                    });
            }

        },

        cancelar() {
            this.usuario = new Usuario();
            this.$router.push({ name: "ControleDeUsuarios" })
        }
    },

    created() {

    }
}
</script>

<style scoped>

</style>

