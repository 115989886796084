<template>
  
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <div class="m-icone direita">
                            <div class="pesquisa">
                                <input type="text" placeholder="Pesquisar grupo por nome" v-model="filtro" @input="pesquisaComFiltro" />
                                <a class="icone-pesquisa" title="Pesquise"></a>
                            </div>
                        </div>
                        <h2>Grupo</h2>
                    </div>
                </div>
                <div class="margem container">
                    <div class="submit m-b">
                        <button @click="adicionarGrupo">Cadastrar Grupo</button>
                    </div>
                    <div class="bloco margem">
                        <table class="tabela alinha-centro">
                            <thead class="alinha-centro">
                                <tr >
                                    <th>Nome</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody class="alinha-centro">
                                <tr v-for="item in gruposFiltrados" :key="item.id">
                                    <td>{{ item.nome }}</td>
                                    <td>
                                        <div class="alinha-centro" style="display: flex; width: 100%; justify-content: center;">
                                            <div>
                                                <a style="transform: scale(0.8)" @click="editarGrupo(item)" title="Clique para editar o grupo" class="icone-editar"></a> 
                                            </div>
                                            <div>
                                                <a @click="abrirModalExcluir(item)" title="Clique para excluir grupo" class="icone-lixeira"></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- MODAL EXCLUIR -->
                <div class="modal-mask" v-if="showDeleteModal" @click="fecharModalFora">
                    <div class="modal-container" style="height: min-content; width: 50rem;">
                        <div style="display: flex; justify-content: center"></div>
                        <br>
                        <div class="modal-body">
                            <span>Confirma a exclusão do grupo: <strong>{{ nomeToDelete }}</strong>?</span>
                         
                            <div class="modal-footer">
                                <br><br>
                                <button type="button" @click="confirmarExclusao">Confirmar</button>&nbsp;&nbsp;
                                <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL EXCLUIR -->
            </section>
     
</template>

<script>
import grupoService from '@/services/grupo-service'
import Grupo from '@/models/Grupo'
import { api } from "roboflex-thalamus-request-handler"; 
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: "ControleDeGrupo",
    data() {
        return {
            grupos: [],
            filtro: '',
            idToDelete: null,
            nomeToDelete: '',
            showDeleteModal: false,
        };
    },
    computed: {
        gruposFiltrados() {
            const buscaNome = this.filtro.toLowerCase();
            return this.grupos.filter(item =>
                item.nome.toLowerCase().includes(buscaNome)
            );
        },
    },
    methods: {
        getAll() {
            grupoService.obterTodos()
                .then((response) => {
                    this.grupos = response.data.data.map((p) => new Grupo(p));
                })
                .catch(error => {
                    console.log(error)
                })
        },
        adicionarGrupo() {
            this.$router.push({ name: "AdicionarGrupo" })
        },
        editarGrupo(grupo) {
            this.$router.push({ name: "EditarGrupo", params: { id: grupo.id } })
        },
        abrirModalExcluir(grupo) {
            this.idToDelete = grupo.id;
            this.nomeToDelete = grupo.nome;
            this.showDeleteModal = true;
        },
        confirmarExclusao() {
            const id = this.idToDelete;
            const index = this.grupos.findIndex(item => item.id === id);

            if (index !== -1) {
                api.delete(`grupo/${id}`)
                    .then(response => {
                        console.log('Response', response);
                        if (response.status === 200 || response.status === 204) {
                            this.getAll();
                            toaster.show(`Grupo excluido com sucesso`, { type: "success" });
                        } else {
                            console.error('Falha ao deletar', response.status);
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
            this.fecharModal();
        },
        fecharModal() {
            this.showDeleteModal = false;
        },
        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.fecharModal();
            }
        },
        pesquisaComFiltro() {
          
        },
    },
    mounted() {
        this.getAll()
    }
};
</script>

