<template>
    
  
      
            <aside>
                <div class="titulo margem efeito">
                    <div class="m-icone"><a href="#" class="icone-menu" title="Menu" onclick="document.getElementById('main').classList.toggle('esconde');return false"></a></div>
                    <nav class="jm jm-inc none" id="menuIncluir">
                        <div class="separador">
                            <a href="#">Incluir projeto</a>

                            <a href="monitoramento.html">Incluir projeto</a>
                        </div>
                    </nav>
                </div>
                <nav class="nav-maior separador">
                    <!-- <router-link  to="/colaboradores/novo" class="icone-inc">Colaborador</router-link >
                    <router-link  to="/grupo/novo" class="icone-inc">Grupo</router-link >
                    <router-link  to="/usuario/novo" class="icone-inc">Usuário</router-link >
                    <router-link  to="/setor/novo" class="icone-inc">Setor</router-link >
                    <router-link to="/funcionalidade/novo" class="icone-inc">Funcionalidade</router-link> -->
                </nav>
                <nav class="nav-maior separador">
                    <router-link to="/" active-class="ativo">Colaborador</router-link>
                    <router-link to="/grupo" active-class="ativo">Grupo</router-link>
                    <!-- <router-link to="/vinculo-de-grupo" active-class="ativo">Vincular Grupo</router-link> -->
                    <router-link to="/setores" active-class="ativo">Setor</router-link>
                    <router-link to="/usuario" active-class="ativo">Usuário</router-link>
                    <router-link to="/vinculo-de-funcionalidade" active-class="ativo">Vinculo de Funcionalidade e Grupo</router-link>
                    <router-link to="/funcionalidade" active-class="ativo">Funcionalidade</router-link>
                </nav>
            </aside>
           
</template>

<script>

export default {
    name: 'NovoMenuLateral',


   

};
</script>
