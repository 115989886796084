<template>
   <body>
    <HeaderComponent :moduleName="$moduleName" :buildMode="envMode"/>

        <main id="main">
            <menuLateralComponentVue></menuLateralComponentVue>
          
                <router-view/>
            </main>

       </body>



</template>

<script>
import menuLateralComponentVue from './components/menuLateral/MenuLateralComponent.vue'
import { HeaderComponent } from "roboflex-thalamus-componentes-vue3-lib";


export default {
  data() {
      return {
        envMode: process.env.NODE_ENV

      }
  },

  components: {
    menuLateralComponentVue: menuLateralComponentVue,
    HeaderComponent
  }
}
</script>


<style>
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--cor-separador);
    border-radius: 20px;
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container {
    background-color: var(--cor-bg);
    border-radius: 8px;
    padding: 100px;
    width: 90%;
    max-width: 1500px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}
</style>