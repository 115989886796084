<template>
   
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <h2>Vincular usuário e funcionalidades a grupos</h2>
                    </div>
                </div>
                <div class="container margem">
                        <label>Selecione um grupo: </label>
                        <select style="width: 500px;" v-model="grupoSelecionado" @change="handleGrupoSelecionadoChange">
                            <option value="" disabled>Selecione</option>
                            <option v-for="item in grupos" :key="item.id" :value="item.id">{{ item.nome }}</option>
                        </select>

                    </div>
                <div class="grid-2 margem container">
                    
                    <div v-if="grupoSelecionado" class="bloco margem">
                       
                        <div v-if="grupoSelecionado">
                            <br><br>
                            <label>Usuários para adicionar:</label>
                            <input style="width: 400px;" v-model="searchQueryUser" @focus="abrirListaUser" @input="filtrarUsers" @blur="fecharListaUser" placeholder="Pesquisar Usuário" type="text" />
                            <div v-if="listaAbertaUser && filteredUsers.length" style="background-color: var(--cor-bg); z-index: 99999; max-height: 20rem; overflow: auto; position: absolute; width: 20.5rem; border: 1px solid var(--cor-separador);">
                                <ul style="list-style: none;">
                                    <li v-for="user in filteredUsers" :key="user.id">
                                        <div @click="toggleUser(user)" style="display: flex; align-items: center; padding: 5px;" :style="{'color': isUserInGroup(user) ? 'gray' : 'var(--cor-fonte)', 'cursor': isUserInGroup(user) ? 'not-allowed' : 'pointer'}">
                                            {{ user.name }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div style="max-height: 500px; overflow-y: auto; overflow-x: hidden;">
                            <ul style="list-style: none;">
                                <div >
                                    <div style="width: fit-content; border: 1px solid black; border-radius: 10px; padding: 5px; margin: 5px;" class="col" v-for="(responsavel, index) in grupoUsuario" :key="index">
                                        <li>{{ responsavel.name }}
                                            <i class="fa-solid fa-circle-xmark" style="color: var(--cor-erro);" @click="desassociarUsuario(responsavel)"></i>
                                        </li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>

                    <div v-if="grupoSelecionado" class="bloco margem">
                        <div v-if="grupoSelecionado">
                            <br><br>
                            <label>Funcionalidades para adicionar:</label>
                            <input style="width: 400px;" v-model="searchQueryFunc" @focus="abrirListaFunc" @input="filtrarFuncionalidades" @blur="fecharListaFunc" placeholder="Pesquisar Funcionalidade" type="text" />
                            <div v-if="listaAbertaFunc && filteredFuncionalidades.length" style="background-color: var(--cor-bg); z-index: 99999; max-height: 20rem; overflow: auto; position: absolute; width: 20.5rem; border: 1px solid var(--cor-separador);">
                                <br>
                                <ul style="list-style: none;">
                                    <li v-for="func in filteredFuncionalidades" :key="func.id">
                                        <div @click="toggleFunc(func)" style="display: flex; align-items: center; padding: 5px;" :style="{'color': isFuncInGroup(func) ? 'gray' : 'var(--cor-fonte)', 'cursor': isFuncInGroup(func) ? 'not-allowed' : 'pointer'}">
                                            {{ func.nome }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div style="max-height: 500px; overflow-y: auto; overflow-x: hidden;">
                            <ul style="list-style: none;">
                                <div >
                                    <div style="width: fit-content; border: 1px solid black; border-radius: 10px; padding: 5px; margin: 5px;" class="col" v-for="(func, index) in funcionalidadesNoGrupo" :key="index">
                                        <li>{{ func.nome }}
                                            <i class="fa-solid fa-circle-xmark" style="color: var(--cor-erro);" @click="desassociarFunc(func)"></i>
                                        </li>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
     
</template>

<script>
import grupoService from '@/services/grupo-service';
import Grupo from '@/models/Grupo';
import usuarioService from '@/services/usuario-service';
import Usuario from '@/models/Usuario';
import grupoUsuarioService from '@/services/grupo_usuario-service';
import funcGrupoService from '@/services/func_grupo-service';
import funcService from '@/services/funcionalidade-service';

export default {
    name: "VinculoDeGrupoEFuncionalidade",
    data() {
        return {
            grupos: [],
            grupoSelecionado: null,
            usuarios: [],
            grupoUsuario: [],
            searchQueryUser: "",
            filteredUsers: [],
            listaAbertaUser: false,
            funcionalidades: [],
            funcionalidadesNoGrupo: [],
            searchQueryFunc: "",
            filteredFuncionalidades: [],
            listaAbertaFunc: false
        }
    },
    methods: {
        abrirListaUser() {
            this.listaAbertaUser = true;
        },
        filtrarUsers() {
            const query = this.searchQueryUser.toLowerCase();
            this.filteredUsers = this.usuarios.filter(user => user.name.toLowerCase().includes(query));
        },
        fecharListaUser() {
            setTimeout(() => {
                this.listaAbertaUser = false;
                this.filteredUsers = [];
            }, 200);
        },
        toggleUser(user) {
            if (this.isUserInGroup(user)) {
                this.desassociarUsuario(user);
            } else {
                this.associarUsuario(user);
            }
        },
        associarUsuario(user) {
            grupoUsuarioService.cadastrar(user.id, this.grupoSelecionado)
                .then(() => {
                    this.grupoUsuario.push(user);
                    this.filtrarUsers(); 
                })
                .catch(error => {
                    console.error('Error associating user:', error);
                });
        },
        desassociarUsuario(user) {
            grupoUsuarioService.deletarTeste(user.id, this.grupoSelecionado)
                .then(() => {
                    this.grupoUsuario = this.grupoUsuario.filter(u => u.id !== user.id);
                    this.filtrarUsers(); 
                })
                .catch(error => {
                    console.error('Error disassociating user:', error);
                });
        },
        isUserInGroup(user) {
            return this.grupoUsuario.some(u => u.id === user.id);
        },
        abrirListaFunc() {
            this.listaAbertaFunc = true;
        },
        filtrarFuncionalidades() {
            const query = this.searchQueryFunc.toLowerCase();
            this.filteredFuncionalidades = this.funcionalidades.filter(func => 
                func.nome.toLowerCase().includes(query)
            );
        },
        fecharListaFunc() {
            setTimeout(() => {
                this.listaAbertaFunc = false;
                this.filteredFuncionalidades = [];
            }, 200);
        },
        toggleFunc(func) {
            if (this.isFuncInGroup(func)) {
                this.desassociarFunc(func);
            } else {
                this.associarFunc(func);
            }
        },
        associarFunc(func) {
            funcGrupoService.cadastrar(func.id, this.grupoSelecionado)
                .then(() => {
                    this.funcionalidadesNoGrupo.push(func);
                    this.filtrarFuncionalidades(); 
                })
                .catch(error => {
                    console.error('Error associating funcionalidade:', error);
                });
        },
        desassociarFunc(func) {
            funcGrupoService.deletarTeste(func.id, this.grupoSelecionado)
                .then(() => {
                    this.funcionalidadesNoGrupo = this.funcionalidadesNoGrupo.filter(f => f.id !== func.id);
                    this.filtrarFuncionalidades();
                })
                .catch(error => {
                    console.error('Error disassociating funcionalidade:', error);
                });
        },
        isFuncInGroup(func) {
            return this.funcionalidadesNoGrupo.some(f => f.id === func.id);
        },
        handleGrupoSelecionadoChange() {
            if (this.grupoSelecionado) {
                this.obterUsuarioPorGrupo(this.grupoSelecionado);
                this.obterFuncionalidadesGrupo(this.grupoSelecionado);
            }
        },
        obterUsuarioPorGrupo(id) {
            grupoUsuarioService.obterPorId(id)
                .then(response => {
                    this.grupoUsuario = response.data;
                    this.filtrarUsers();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        obterFuncionalidadesGrupo(id) {
            funcGrupoService.obterFuncionalidade(id)
                .then(response => {
                    this.funcionalidadesNoGrupo = response.data;
                    this.filtrarFuncionalidades();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getAllUsuarios() {
            usuarioService.obterTodos()
                .then(response => {
                    this.usuarios = response.data.map(p => new Usuario(p));
                    this.filtrarUsers();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getAllFuncionalidades() {
            funcService.obterTodos()
                .then(response => {
                    this.funcionalidades = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getAllGrupos() {
            grupoService.obterTodos()
                .then(response => {
                    this.grupos = response.data.data.map(p => new Grupo(p));
                })
                .catch(error => {
                    console.log(error);
                });
        },
    },
    mounted() {
        this.getAllGrupos();
        this.getAllUsuarios();
        this.getAllFuncionalidades();
    }
}
</script>
