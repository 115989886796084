import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import VueMask from '@devindex/vue-mask'; 

import './assets/css/thalamus.css'

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

const app = createApp(App)
app.config.globalProperties.$moduleName = 'Estrutura'

app.use(router)
app.use(VueMask);

app.mount('#app')
