<template>
            <section>
                <div class="titulo">
                    <div class="margem container">
    
                        <h2>{{ modoCadastro ? "Cadastrar" : "Editar" }} Funcionalidades</h2>
                    </div>
                </div>
                <div class=" margem container">
                    <fieldset class="grid-2 bloco margem">
    
    
                        <div>
                            <label for="nomeCompleto">Nome</label>
                            <input type="text" id="nomeCompleto" v-model="funcionalidade.nome">
                        </div>
                        <div>
                            <label for="url">URL</label>
                            <input type="text" id="url" v-model="funcionalidade.URL">
                        </div>
                            <!-- <label for="url">Menu</label> -->
    
                            <!-- <div class="tags" style="justify-content: center;">
                                                <a @click="item.concordo = true"
                                                    :class="item.concordo == true ? 'ativo' : ''">Sim</a>
                                                <a @click="item.concordo = false"
                                                    :class="item.concordo == false ? 'ativo' : ''">Não</a>
                                            </div>  -->
    
    
                                <!-- <input type="radio" id="sim" value="1" v-model="funcionalidade.menu">
                            <label for="sim"> Sim </label>
                            <input type="radio" id="nao" value="0" v-model="funcionalidade.menu">
                            <label for="nao"> Não </label> -->
                           
    
                        <div>
                            <label for="setor">Sistema</label>
                            <select v-model="funcionalidade.sistema_id">
                                        <option v-for="item in sistemasDisponiveis" :key="item.id" :value="item.id">{{ item.nome }}</option>
                                    </select>
                        </div>
    
    
    
                    </fieldset>
    
    
                </div>
                <div class="grid-2  margem container">
                    <div class="submit m-b">
                        <button @click="salvarFuncionalidade">Salvar</button>
                        <button @click="cancelar" class="acao-secundaria">Cancelar</button>
                    </div>
    
    
    
                </div>
    
                <!-- MODAL QRCODE -->
                <div class="modal-mask" v-if="showModalQRCode" @click="fecharModalForaQRCode">
                    <div class="modal-container" style="height: min-content; width: 50rem;">
                        <div style="display: flex; justify-content: center"></div>
                        <br>
                        <div class="modal-body">
                            <h1 class="modal-title fs-5">Fazendo leitura do QR Code</h1>
                            <div class="camera">
                                <qrcode-stream v-if="cameraAberta && !qrcodeWebcam" @decode="onDecode" @init="onInit" />
                            </div>
                            <div>
                                <span v-if="mostraAlerta" style="color: var(--cor-sucesso); font-weight: 600;">QR Code lido</span>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" @click="fecharModalQRCode" class="btn btn-primary">Ok</button>
                        </div>
                    </div>
                </div>
                <!-- END MODAL QRCODE -->
    
    
                <!-- MODAL EDIÇÃO-->
                <div v-if="modalEdicao" class="modal-mask" @click="fecharModalFora">
                    <div class="modal-container" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="max-width: 20%;">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Deseja ativar o Modo Edição ?</h1>
                                <button type="button" class="btn-close" @click="fecharModalEdicao()" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <br>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col d-flex justify-content">
                                        <button class="button-cadastro" @click="confirmarEdicao(true)">Sim</button>&nbsp;&nbsp;
                                        <button class="button-cadastro" @click="confirmarEdicao(false)">Não</button>
                                        <br><br>
                                    </div>
                                    <br><br>
    
                                </div>
                                <br>
                                <span @click="downloadPlanilha" style="text-decoration: underline; cursor: pointer;">Download Planilha&nbsp;<i class="fa-solid fa-download"></i></span>
    
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL EDIÇÃO -->
            </section>
</template>

<script>
import sistemaService from '@/services/sistema-service';
import Sistema from '@/models/Sistema';
import Funcionalidade from '@/models/Funcionalidade'
import funcionalidadeService from '@/services/funcionalidade-service';
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: "FuncionalidadeComponent",
    components: {},
    data() {
        return {
            funcionalidade: new Funcionalidade(),
            modoCadastro: true,
            sistemasDisponiveis: [],
            loading: false,
        }
    },

    computed: {

    },

    mounted() {


        this.getAllSistemas()

        let id = this.$route.params.id;
        if (!id) {
            return;
        }


        this.modoCadastro = false
        this.obterFuncporId(id)


    },
    methods: {

        getAllSistemas() {
            sistemaService.obterTodos()
                .then(response => {
                    this.sistemasDisponiveis = response.data.data.map((p) => new Sistema(p));
                })
                .catch(error => {
                    console.log(error)
                })
        },


        obterFuncporId(id) {
            funcionalidadeService.obterPorId(id)
                .then(response => {
                    this.funcionalidade = new Funcionalidade(response.data);
                })
                .catch(error => {
                    console.log(error)
                })
        },
        cadastrarFuncionalidade() {
            this.loading = true

            if (!this.funcionalidade.nome || !this.funcionalidade.URL) {
                this.loading = false
                toaster.show(`Por favor preencha o nome da funcionalidade e a URL`, { type: "error" });

            } else {
                funcionalidadeService.cadastrar(this.funcionalidade)
                    .then(() => {
                        this.$router.push({ name: "ControleDeFuncionalidade" })
                        toaster.show(`Funcionalidade cadastrada com sucesso!`, { type: "success" });
                        this.funcionalidade = new Funcionalidade();
                        this.loading = false


                    })
                    .catch(error => {
                        this.loading = false
                        toaster.show(`Erro no cadastro de funcionalidade`, { type: "error" });
                        console.log(error);
                    });
            }


        },

        salvarFuncionalidade() {

            (this.modoCadastro) ? this.cadastrarFuncionalidade(): this.atualizarFuncionalidade();


        },
        atualizarFuncionalidade() {
            this.loading = true

            if (!this.funcionalidade.nome || !this.funcionalidade.URL) {
                this.loading = false
                toaster.show(`Por favor preencha nome e URL`, { type: "error" });

            } else {
                funcionalidadeService.atualizar(this.funcionalidade)
                    .then(() => {
                            this.loading = false
                            toaster.show(`Por favor preencha nome e URL`, { type: "error" });
                            this.$router.push({ name: "ControleDeFuncionalidade" });
                        }

                    )

                    .catch(error => {
                        this.loading = false
                        toaster.show(`Erro ao atualizar funcionalidade`, { type: "error" });
                        console.log(error);

                    });
            }

        },

        cancelar() {
            this.funcionalidade = new Funcionalidade();
            this.$router.push({ name: "ControleDeFuncionalidade" })
        },

    }
}
</script>

<style scoped>

</style>

