<template>
    <section>
     
                <div class="titulo">
                    <div class="margem container">
                        <div class="m-icone direita">
                            <div class="pesquisa">
                                <input type="text" placeholder="Pesquise aqui" v-model="filtroNome" @input="pesquisaComFiltro" />
                                <a class="icone-pesquisa" title="Pesquise"></a>
                            </div>
                        </div>
                        <h2>Usuários</h2>
                    </div>
                </div>
                <div class="margem container">
                    <div class="submit m-b">
                        <button @click="adicionarUsuario">Cadastrar Usuário</button>
                    </div>
                    <div class="bloco margem">
                        <table class="tabela alinha-centro">
                            <thead class="alinha-centro">
                                <tr>
                                    <th>Nome</th>
                                    <th>Email</th>
                                    <!-- <th>Grupo ID</th> -->
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody class="alinha-centro">
                                <tr v-for="item in usuariosFiltrados" :key="item.id">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.email }}</td>
                                    <!-- <td>{{ item.grupo_id }}</td> -->
                                    <td>
                                        <div class="alinha-centro" style="display: flex; width: 100%; justify-content: center;">
                                            <div>
                                                <a style="transform: scale(0.8)" @click="editarUsuario(item)" title="Clique para editar o usuário" class="icone-editar"></a>
                                            </div>
                                            <div>
                                                <a @click="abrirModalExcluir(item)" title="Clique para excluir usuário" class="icone-lixeira"></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- MODAL EXCLUIR -->
                <div class="modal-mask" v-if="showDeleteModal" @click="fecharModalFora">
                    <div class="modal-container" style="height: min-content; width: 50rem;">
                        <div style="display: flex; justify-content: center"></div>
                        <br>
                        <div class="modal-body">
                            <span>Confirma a exclusão do usuário: <strong>{{ nomeToDelete }}</strong>?</span>
                            <div class="modal-footer">
                                <br><br>
                                <button type="button" @click="confirmarExclusao">Confirmar</button>&nbsp;&nbsp;
                                <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL EXCLUIR -->
      </section>
</template>

<script>
import usuarioService from '@/services/usuario-service'
import Usuários from '@/models/Usuario'
import Grupo from '@/models/Grupo'
import grupoService from '@/services/grupo-service'
import { api } from "roboflex-thalamus-request-handler";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: "ControleDeUsuarios",
    data() {
        return {
            usuarios: [],
            grupoUsuario: [],
            filtroNome: '',
            idToDelete: null,
            nomeToDelete: null,
            showDeleteModal: false,
        };
    },
    computed: {
        usuariosFiltrados() {
            return this.usuarios.filter(item => {
                return item.name.toLowerCase().includes(this.filtroNome.toLowerCase());
            });
        }
    },
    methods: {
        obterGrupoPorId(id) {
            grupoService.obterPorId(id)
                .then(response => {
                    this.grupoUsuario = new Grupo(response.data);
                })
                .catch(error => {
                    console.log(error)
                })
        },
        getAll() {
            usuarioService.obterTodos()
                .then((response) => {
                    this.usuarios = response.data.map((p) => new Usuários(p));
                })
                .catch(error => {
                    console.log(error)
                })
        },
        adicionarUsuario() {
            this.$router.push({ name: "AdicionarUsuario" })
        },
        editarUsuario(usuario) {
            this.$router.push({ name: "EditarUsuario", params: { id: usuario.id } })
        },
        abrirModalExcluir(usuario) {
            this.idToDelete = usuario.id;
            this.nomeToDelete = usuario.name;
            this.showDeleteModal = true;
        },
        confirmarExclusao() {
            const id = this.idToDelete;
            const index = this.usuarios.findIndex(item => item.id === id);

            if (index !== -1) {
                api.delete(`usuario/${id}`)
                    .then(response => {
                        if (response.status === 200 || response.status === 204) {
                            this.getAll();
                            toaster.show(`Usuário excluído com sucesso`, { type: "success" });
                        } else {
                            toaster.show(`Falha ao deletar`, { type: "error" });
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        toaster.error("Erro ao excluir usuário");
                    });
            }
            this.fecharModal();
        },
        fecharModal() {
            this.showDeleteModal = false;
        },
        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.fecharModal();
            }
        },
        pesquisaComFiltro() {
            // Atualização automática da lista filtrada ao digitar
        }
    },
    mounted() {
        this.getAll();
    }
};
</script>

<style scoped>
</style>
