<template>
 
            <section>
                <div class="titulo">
                    <div class="margem container">
    
                        <h2>{{ modoCadastro ? "Cadastrar" : "Editar" }} Grupo</h2>
                    </div>
                </div>
                <div class="grid-2 margem container">
                    <fieldset class="grid-2 bloco margem">
    
                        <div>
                            <label for="nomeCompleto">Nome</label>
                            <input type="text" id="nomeCompleto" v-model="grupo.nome">
                        </div>
    
                    </fieldset>
    
                </div>
                <div class="grid-2  margem container">
                    <div class="submit m-b">
                        <button @click="salvarGrupo">Salvar</button>
                        <button @click="cancelar" class="acao-secundaria">Cancelar</button>
                    </div>
    
                </div>
            </section>
 
</template>


<script>
import Grupo from '@/models/Grupo'
import grupoService from '@/services/grupo-service';
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: "GrupoComponent",
    components: {},
    data() {
        return {
            grupo: new Grupo(),
            modoCadastro: true,
            loading: false
        }
    },


    mounted() {
        let id = this.$route.params.id;
        if (!id)

            return;

        this.modoCadastro = false;
        this.obterGrupoPorId(id);

    },
    methods: {
        obterGrupoPorId(id) {
            grupoService.obterPorId(id)
                .then(response => {
                    this.grupo = new Grupo(response.data);
                })
                .catch(error => {
                    console.log(error)
                })
        },
        cadastrarGrupo() {
            this.loading = true

            if (!this.grupo.nome) {
                toaster.show(`Por favor preencha o nome`, { type: "error" });
                this.loading = false;

            } else {
                grupoService.cadastrar(this.grupo)
                    .then(() => {
                        this.grupo = new Grupo();
                        this.loading = false
                        toaster.show(`Grupo cadastrado com sucesso!`, { type: "success" });
                        this.$router.push({ name: "ControleDeGrupo" })



                    })
                    .catch(error => {
                        this.loading = false
                        toaster.show(`Erro no cadastro de grupo`, { type: "error" });
                        console.log(error);
                    });
            }


        },

        salvarGrupo() {

            (this.modoCadastro) ? this.cadastrarGrupo(): this.atualizarGrupo();
        },
        atualizarGrupo() {
            this.loading = true


            if (!this.grupo.nome) {
                toaster.show(`Por favor preencha o nome`, { type: "error" });
            } else {
                grupoService.atualizar(this.grupo)
                    .then(() => {
                        toaster.show(`Grupo atualizado com sucesso!`, { type: "success" });

                        this.$router.push({ name: "ControleDeGrupo" });
                        this.loading = false



                    })
                    .catch(error => {
                        toaster.show(`Erro no cadastro de grupo`, { type: "error" });

                        this.loading = false
                        console.log(error);
                    });
            }


        },

        cancelar() {
            this.grupo = new Grupo();
            this.$router.push({ name: "ControleDeGrupo" })
        },


    }
}
</script>

<style scoped>

</style>

