<template>
  
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <div class="m-icone direita">
                            <div class="pesquisa">
                                <input type="text" placeholder="Pesquise aqui" v-model="filtroNome" @input="pesquisaComFiltro" aria-label="Pesquisar Setores" />
                                <a class="icone-pesquisa" title="Pesquise"></a>
                            </div>
                        </div>
                        <h2>Setores</h2>
                    </div>
                </div>
                <div class="margem container">
                    <div class="submit m-b">
                        <button @click="adicionarSetor"><span>Cadastrar Setor</span></button>
                    </div>
                    <div class="bloco margem">
                        <table class="tabela alinha-centro">
                            <thead class="alinha-centro">
                                <tr>
                                    <th>Nome</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody class="alinha-centro">
                                <tr v-for="item in setoresFiltrados" :key="item.id">
                                    <td>{{ item.nome }}</td>
                                    <td>
                                        <div class="alinha-centro" style="display: flex; width: 100%; justify-content: center;">
                                            <div>
                                                <a style="transform: scale(0.8)" @click="editarSetor(item)" title="Clique para editar setor" class="icone-editar"></a>
                                            </div>
                                            <div>
                                                <a @click="abrirModalExcluir(item)" title="Clique para excluir setor" class="icone-lixeira"></a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- MODAL EXCLUIR -->
                <div class="modal-mask" v-if="showDeleteModal" @click="fecharModalFora">
                    <div class="modal-container" style="height: min-content; width: 50rem;">
                        <div style="display: flex; justify-content: center"></div>
                        <br>
                        <div class="modal-body">
                            <span>Confirma a exclusão do setor: <strong>{{ nomeToDelete }}</strong>?</span>
                            <div class="modal-footer">
                                <br><br>
                                <button type="button" @click="confirmarExclusao"><span>Confirmar</span></button>&nbsp;&nbsp;
                                <button type="button" @click="fecharModal" class="acao-secundaria"><span>Cancelar</span></button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- END MODAL EXCLUIR -->

            </section>
       
</template>

<script>
import setorService from '@/services/setor-service'
import Setores from '@/models/Setor'
import conversorDeData from '../../utils/conversor-data'
import { api } from "roboflex-thalamus-request-handler";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: "ControleDeSetores",
    components: {},
    filters: {
        data(data) {
            return conversorDeData.dataConversor(data);
        }
    },
    data() {
        return {
            setores: [],
            info: null,
            currentPage: 0,
            itemsPerPage: 10,
            filtroNome: '',
            idToDelete: null,
            nomeToDelete: null,
            showDeleteModal: false,
        };
    },
    computed: {
       

        setoresFiltrados(){
            return this.setores.filter(item => {
                return item.nome.toLowerCase().includes(this.filtroNome.toLowerCase());
            })
        }
    },
    methods: {
        getAll() {
            setorService.obterTodos()
                .then((response) => {
                    this.setores = response.data.map((p) => new Setores(p));
                })
                .catch(error => {
                    console.log(error);
                    // toaster.error("Erro ao carregar setores");
                });
        },
        adicionarSetor() {
            this.$router.push({ name: "AdicionarSetor" });
        },
        editarSetor(setor) {
            this.$router.push({ name: "EditarSetor", params: { id: setor.id } });
        },
        abrirModalExcluir(setor) {
            this.idToDelete = setor.id;
            this.nomeToDelete = setor.nome;
            this.showDeleteModal = true;
        },
        confirmarExclusao() {
            const id = this.idToDelete;
            const index = this.setores.findIndex(item => item.id === id);

            if (index !== -1) {
                api.delete(`setor/${id}`)
                    .then(response => {
                        if (response.status === 200 || response.status === 204) {
                            this.buscaSetor(this.page);
                            toaster.show(`Setor excluído com sucesso`, { type: "success" });
                        } else {
                            toaster.show(`Falha ao deletar`, { type: "error" });
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        toaster.error("Erro ao excluir setor");
                    });
            }
            this.fecharModal();
        },
        fecharModal() {
            this.showDeleteModal = false;
        },
        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.fecharModal();
            }
        },
        async buscaSetor(page) {
            try {
                const response = await fetch(`setor?page=${page}`);
                const contentType = response.headers.get("content-type");

                if (contentType && contentType.includes("application/json")) {
                    const responseData = await response.json();
                    this.setores = responseData.data || [];
                    this.lastPage = responseData.last_page || 1;
                    this.page = page;
                } else {
                    console.error('Expected JSON response but received:', contentType);
                    // toaster.error("Erro ao carregar dados dos setores");
                }
            } catch (error) {
                console.error('Error:', error);
                // toaster.error("Erro ao carregar dados dos setores");
            }
        },
        
        pesquisaComFiltro() {
            this.currentPage = 0;
        }
    },
    mounted() {
        this.getAll();
        this.buscaSetor(this.page);
    }
};
</script>
