import { createRouter, createWebHistory } from 'vue-router'
import { sso } from "roboflex-thalamus-sso-lib";


//Permissão
import ColaboradorView from "@/views/Permissão/ColaboradorView"
import ControleDeColaborador from "@/views/Permissão/ControleDeColaborador"
import GrupoView from "@/views/Permissão/GrupoView"
import ControleDeGrupo from "@/views/Permissão/ControleDeGrupo"
import VinculoGrupo from "@/views/Permissão/VinculoGrupo"
import SetorView from "@/views/Permissão/SetorView"
import ControleDeSetores from "@/views/Permissão/ControleDeSetores"
import UsuarioView from "@/views/Permissão/UsuarioView"
import ControleDeUsuarios from "@/views/Permissão/ControleDeUsuarios"
import ControleDeFuncionalidade from "@/views/Permissão/ControleDeFuncionalidade"
import VinculoFuncionalidade from "@/views/Permissão/VinculoFuncionalidade"
import FuncionalidadeView from "@/views/Permissão/FuncionalidadeView"
//Menu Component
// import MenuComponent from "../components/menu/MenuComponent"
//Menu View
// import GestaoView from "@/views/Menu/GestaoView"
// import FabricaView from "@/views/Menu/FabricaView"
// import AdministrativoView from "@/views/Menu/AdministrativoView"
function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
   

    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}

const routes = [

  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },
 
  
  
 
  //Permissão
  {
    path: '/',
    name: 'ControleDeColaborador',
    component: ControleDeColaborador,
    beforeEnter: guardMyroute,

   
  },
  {
    path: '/colaboradores/novo',
    name: 'AdicionarColaborador',
    component: ColaboradorView,
    title: 'Adicionar Colaborador',
    beforeEnter: guardMyroute

  },
  {
    path: '/colaboradores/editar/:id',
    name: 'ColaboradorEditar',
    component: ColaboradorView,
    title: 'Editar Colaborador' ,
    beforeEnter: guardMyroute,
 
  },
  {
    path: '/colaboradores/excluir',
    name: 'ExcluirColaborador',
    component: ColaboradorView,
    title: 'Excluir Colaborador',
    beforeEnter: guardMyroute,

  },
  {
    path: '/grupo',
    name: 'ControleDeGrupo',
    component: ControleDeGrupo,
    title: 'Grupos',
    beforeEnter: guardMyroute,

    
  },
  {
    path: '/controle-de-grupo/novo',
    name: 'AdicionarGrupo',
    component: GrupoView,
    title: 'Adicionar Grupo',
    beforeEnter: guardMyroute,

    
  },
  {
    path: '/controle-de-grupo/editar/:id',
    name: 'EditarGrupo',
    component: GrupoView,
    title: 'Editar Grupo',
    beforeEnter: guardMyroute,

    
  },
  {
    path: '/controle-de-grupo/excluir',
    name: 'ExcluirGrupo',
    component: GrupoView,
    title: 'Excluir Grupo',
    beforeEnter: guardMyroute,

    
  },
  {
    path: '/vinculo-de-grupo',
    name: 'VincularGrupo',
    component: VinculoGrupo,
    title: 'Vincular Grupo',
    beforeEnter: guardMyroute,

    
  },
  {
    path: '/setores',
    name: 'ControleDeSetores',
    component: ControleDeSetores,
    title: 'Setores',
    beforeEnter: guardMyroute,

  },
  {
    path: '/controle-de-setores/novo',
    name: 'AdicionarSetor',
    component: SetorView,
    title: 'Adicionar Setor',
    beforeEnter: guardMyroute,


  },
  {
    path: '/controle-de-setores/editar/:id',
    name: 'EditarSetor',
    component: SetorView,
    title: 'Editar Setor',
    beforeEnter: guardMyroute,

  },
  {
    path: '/controle-de-grupo/excluir',
    name: 'ExcluirSetor',
    component: SetorView,
    title: 'Excluir Setor',
    beforeEnter: guardMyroute,

    
  },
  {
    path: '/usuario',
    name: 'ControleDeUsuarios',
    component: ControleDeUsuarios,
    title: 'Usuario' ,
    beforeEnter: guardMyroute,
 
  },
  {
    path: '/usuario/novo',
    name: 'AdicionarUsuario',
    component: UsuarioView,
    title: 'Adicionar Usuario'  ,
    beforeEnter: guardMyroute,

  },
  {
    path: '/usuario/editar/:id',
    name: 'EditarUsuario',
    component: UsuarioView,
    title: 'Editar Usuario' ,
    beforeEnter: guardMyroute,
 
  },
  {
    path: '/usuario/excluir',
    name: 'ExcluirUsuario',
    component: UsuarioView,
    title: 'Excluir Usuario' ,
    beforeEnter: guardMyroute,

  },
  {
    path: '/vinculo-de-funcionalidade',
    name: 'VinculoDeFuncionalidade',
    component: VinculoFuncionalidade,
    title: 'Vincular Funcionalidade',
    beforeEnter: guardMyroute,

    
  },
  {
    path: '/funcionalidade',
    name: 'ControleDeFuncionalidade',
    component: ControleDeFuncionalidade,
    title: 'Funcionalidade' ,
    beforeEnter: guardMyroute,
 
  },
  {
    path: '/funcionalidade/novo',
    name: 'AdicionarFuncionalidade',
    component: FuncionalidadeView,
    title: 'Adicionar Funcionalidade' ,
    beforeEnter: guardMyroute,
 
  },
  {
    path: '/funcionalidade/editar/:id',
    name: 'EditarFuncionalidade',
    component: FuncionalidadeView,
    title: 'Editar Funcionalidade',
    beforeEnter: guardMyroute,
  
  },
  {
    path: '/funcionalidade/excluir',
    name: 'ExcluirFuncionalidade',
    component: FuncionalidadeView,
    title: 'Excluir Funcionalidade',
    beforeEnter: guardMyroute,
  
  },
 


]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})



    
export default router
